require('./bootstrap');
import { Toast } from 'bootstrap'
const toast = new Toast(document.getElementById('liveToast'));

const countMortgage = (housePrice, mortgageSum, loanPercent) => {
  if (housePrice.value) {
    if (mortgageSum.value) {
      let percent = mortgageSum.value / housePrice.value * 100;
      loanPercent.value = Math.round((percent + Number.EPSILON) * 100) / 100
    }
  }
};

const countPercent = (housePrice, mortgageSum, loanPercent) => {
  if (housePrice.value) {
    if (loanPercent.value) {
      mortgageSum.value = housePrice.value * (loanPercent.value / 100);
    }
  }
};

const appendEmbedScript = (modal) => {
  console.log('shown');
  const script = document.createElement("script")
  script.setAttribute('defer', true)
  script.id = 'embededform'
  script.src = 'https://forms.hypotheekonline.nl/js/embed.js'
  document.head.appendChild(script)
}

const removeEmbeded = (modal) => {
  console.log('about to show');
  const script = document.getElementById('embededform')
  const iframe = modal.querySelector('iframe')
  if (iframe) {
    iframe.remove()
  }
  if (script) {
    script.remove()
  }
}

const appointmentModal = document.getElementById('appointment')
appointmentModal.addEventListener('show.bs.modal', function () {
  removeEmbeded(appointmentModal)
})
appointmentModal.addEventListener('shown.bs.modal', function () {
  appendEmbedScript(appointmentModal)
})
appointmentModal.addEventListener('hidden.bs.modal', function () {
  removeEmbeded(appointmentModal)
})

const calculatorModal = document.getElementById('calculator')
calculatorModal.addEventListener('show.bs.modal', function () {
  removeEmbeded(calculatorModal)
})
calculatorModal.addEventListener('shown.bs.modal', function () {
  appendEmbedScript(calculatorModal)
})
calculatorModal.addEventListener('hidden.bs.modal', function () {
  removeEmbeded(calculatorModal)
})

const afterSubmit = (success, response, loader, toast) => {
  loader.setAttribute('hidden', true);
  document.querySelector('.toast').classList.add('bg-danger');
  document.querySelector('.toast').classList.remove('bg-primary');
  let validated = '';
  let msg = 'Fout!';
  if (typeof response.data.validation_api !== "undefined") {
    let validation = response.data.validation_api;
    validated += '<ol>';
    for (const [key, value] of Object.entries(validation)) {
      validated += `<li>${value[0]}</li>`;
    }
    validated += '</ol>';
  } else {
    validated = response.data.validation;
  }
  let html = `<p>${response.data.message}</p>${validated}`;
  if (success) {
    document.querySelector('.toast').classList.remove('bg-danger');
    document.querySelector('.toast').classList.add('bg-primary');
    msg = 'Success!'
    html = `<p>${response.data.message}</p>`;
  }
  document.querySelector('.toast .toast-header .toast-title').innerText = msg;
  document.querySelector('.toast .toast-body').innerHTML = html;
  toast.show();
}

(function () {
  'use strict'

  let housePrice = document.getElementById('house_price');
  let mortgageSum = document.getElementById('mortgage_sum');
  let loanPercent = document.getElementById('loan_percent');

  "keyup blur".split(" ").forEach(function(e){
    if (mortgageSum) {
      mortgageSum.addEventListener(e, () => {
        countMortgage(housePrice, mortgageSum, loanPercent);
      }, false);
    }
  
    if (loanPercent) {
      loanPercent.addEventListener(e, () => {
        countPercent(housePrice, mortgageSum, loanPercent);
      }, false);
    }

    if (housePrice) {
      housePrice.addEventListener(e, () => {
        if (mortgageSum.value) {
          countMortgage(housePrice, mortgageSum, loanPercent);
        } else if (loanPercent.value) {
          countPercent(housePrice, mortgageSum, loanPercent);
        }
      }, false);
    }
  
  });

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  const forms = document.querySelectorAll('.needs-validation');

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        event.preventDefault();
        event.stopPropagation();
        const loader = form.querySelector('.sending-loader');
        if (form.checkValidity()) {
          loader.removeAttribute('hidden');
          let formData = new FormData(form);
          axios({
            method: "post",
            url: form.action,
            data: formData,
            headers: { 
              "Content-Type": "multipart/form-data",
              'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
              'X-Requested-With': 'XMLHttpRequest'
            },
          })
          .then(function (response) {
            let successfailed = response.data.success ? true: false;
            afterSubmit (successfailed, response, loader, toast);
            console.log(response);
          })
          .catch(function (response) {
            afterSubmit (false, response, loader, toast);
            console.log(response);
          });
        } else {
          let modal_form = form.querySelector('.modal');
          modal_form.classList.add('invalidAct');
          setTimeout(() => {
            modal_form.classList.remove('invalidAct');
          }, 820);
        }

        form.classList.add('was-validated');
      }, false)
    })
})();